import React from "react"
import { Box, Heading, Paragraph } from "grommet"

import Layout from "../components/layout"
import Seo from "../components/seo"

const TermsAndConditionsPage = () => (
  <Layout>
    <Seo title="Terms and conditions" />
    <Box align="center" pad="large">
      <Heading alignSelf="center">Условия за ползване</Heading>
      <Paragraph>В процес на изготвяне</Paragraph>
    </Box>
  </Layout>
)

export default TermsAndConditionsPage
